import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  actionPayment,
  getPaymentREquests,
} from "../../services/User/UserService";
import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function PaymentRequests(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("Pending");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [initialFetch, setInitialFetch] = useState(true);

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getPaymentREquests(currentPage, limit, type);
      console.log(response.data.data.requests);
      setUsers(response.data.data.requests);
      const total = response.data.data?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.total);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const onAction = async (id, actionType) => {
    setLoader(true);
    try {
      const response = await actionPayment(id, actionType);
      actionType === "Confirmed"
        ? notifyTopRight("Confirmed Successfully.")
        : notifyTopRight("Rejected Successfully.");

      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, type]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };
  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 500); // Adjust the delay as needed

  useEffect(() => {
    if (initialFetch === false) {
      // Reset current page when search or status changes
      setCurrentPage(0);
      // Trigger the debounced handleFetch function when search or status changes
      debouncedHandleFetch();
      // Cleanup function
      return () => {
        // Clear any pending API calls if component unmounts or search/status changes again
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <PageTitle activeMenu="Payment List" motherMenu="Payment" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <select
                  className="form-control"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option hidden>Select</option>
                  <option value={"Pending"}>Pending</option>
                  <option value={"Confirmed"}>Confirmed</option>
                  <option value={"Rejected"}>Rejected</option>
                </select>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>PHONE NUMBER</strong>
                    </th>
                    <th>
                      <strong>AMOUNT</strong>
                    </th>
                    <th>
                      <strong>STATUS</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr key={i}>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "/pharmacy-details",
                            state: item,
                          })
                        }
                        className="pointer"
                      >
                        {item?.user?.name}
                      </td>
                      <td>{item?.user?.email}</td>
                      <td>
                        {item?.user?.countryCode
                          ? "+" + item?.user?.countryCode + " "
                          : ""}
                        {item?.user?.phoneNumber}
                      </td>
                      <td>{item?.amount}</td>

                      <td>
                        {item?.status === "Pending" ? (
                          <Badge variant="warning light">Pending</Badge>
                        ) : item?.status === "Confirmed" ? (
                          <Badge variant="success light">Confirmed</Badge>
                        ) : (
                          <Badge variant="danger light">Rejected</Badge>
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          {item?.status === "Pending" && (
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => onAction(item?._id, "Confirmed")}
                              >
                                Confirm
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => onAction(item?._id, "Rejected")}
                              >
                                Reject
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
