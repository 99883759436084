import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import profile from "../../images/blank-profile-picture.webp";
import moment from "moment";
function AppointmentDetails(props) {
  const [modal, setModal] = useState(false);
  const userData = props.location.state;
  console.log(userData, "daatt");
  return (
    <div>
      <div className="page-titles">
        <h4>Appointment Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="appointment-requests">Appointment Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Appointment Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "70%" }}>
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <div className="text-left">
              <h3 className="text-black ">{userData?.bookingFor}</h3>
              <p className="text-black mb-0">{userData?.email} </p>
              <p className="text-black mb-0">
                {userData?.phoneNumber
                  ? "+" + userData?.countryCode + " " + userData?.phoneNumber
                  : ""}{" "}
              </p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
          
          <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Doctor</h5>
              <p className="">
                {userData?.doctor
                  ? userData?.doctor
                  : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Prescriptions</h5>
              <p className="">
                {userData?.prescriptions.length > 0
                  ? userData?.prescriptions
                  : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Pharmacies</h5>
              <p className="">
                {userData?.pharmacies.length > 0
                  ? userData?.prescriptions
                  : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Confirm Slot Time</h5>
              <p className="">
                {userData?.confirmSlotTime &&
                  moment(userData?.confirmSlotTime, "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                ,{moment(userData?.confirmSlotTime).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Reason Visit</h5>
              <p className="">
                {userData?.reasonVisit}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Documents</h5>
              <p className="">
                {userData?.files.map((item,i)=>(
                    <img src={item.file} key={i} width={100} height={100}/>
                ))}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Method</h5>
              <p className="">
                {userData?.method}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>labs</h5>
              <p className="">
              {userData?.labs.length > 0
                  ? userData?.labs
                  : "--"}
              </p>
            </div>
            
            
            
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AppointmentDetails;
