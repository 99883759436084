import React, { useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment/moment";
// import User from "../../../images/DummyUser.png";

function AssistantDoctor({
  name,
  assistantEmail,
  lastAppointmentDate,
}) {
  function capitalizeFirstLetterOfFirstWord(phrase) {
    if (phrase.length === 0) return phrase;
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  }

  return (
    <div className="appointment-card-container">
      <Card className="appointment-card row cursor-pointer animate__animated animate__fadeIn">
        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            <span style={{fontWeight:700}}>Sub Admin :</span>  <span className="appointment-card_field">
              {capitalizeFirstLetterOfFirstWord(name)}
            </span>
          </label>
        </div>

        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
          <span style={{fontWeight:700}}>Email :</span>  <span className="appointment-card_field">{assistantEmail}</span>
          </label>
        </div>

        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
          <span style={{fontWeight:700}}>Created date :</span>  <span className="appointment-card_field">
              {moment(lastAppointmentDate).format("DD, MMM yyyy")}
            </span>
          </label>
        </div>
      </Card>
    </div>
  );
}

export default AssistantDoctor;
