import { toast } from "react-toastify";

// ////toaster success message////

export const notifyTopRight = (success) => {
  toast.success(`${success}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

// ////toaster error message ////

export const notifyError = (error) => {
  toast.error(`${error}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
